<template>
	<div class="container">
		<h3 class="centered is-size-6">Выберите проекты для размещения на портале ГИСОГД</h3>
		<ColumnIasProjectsInput :projects="projects" v-model="selectedProjects"></ColumnIasProjectsInput>
		<div class="columns is-centered">
			<div class="column is-half is-flex">				
				<button class="button is-link centered button-start" @click="publishAllProjects">Разместить проекты</button>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import AgateProjectsApi from '../api/agateProjectsApi';
	import IasProjectsApi from '../api/iasProjectsApi';
	import PublishApi from '../api/publishApi';
	
	import ColumnIasProjectsInput from '../components/projects/ColumnIasProjectsInput';

	export default {
		name: 'init',
		data() {
			return {
				projects: [],
				selectedProjects: []
			}
		},
		components: {			
			ColumnIasProjectsInput
		},
		methods: {
			publishAllProjects() {
				PublishApi
					.publish(this.selectedProjects.map(p => p.schema))
					.then(() => {
						this.$router.push({ path: '/' });
					})
			}
		},
		async created() {
			this.projects = this.selectedProjects = await IasProjectsApi.get();
		},

		async beforeRouteEnter(to, from, next) {
			const agateProjects = await AgateProjectsApi.get();
			if (agateProjects.length > 0)
				next('/');
			else
				next();
		},
	}
</script>
<style lang="scss">
	@import '../assets/css/main.scss';

	.centered {
		text-align: center;
		margin: 4rem auto;

		@include mobile {
			margin: 1.2rem auto;
		}
	}

	.button-start {
		width: 300px;
	}
</style>
