<template>
	<ul class="projects">
		<li v-for="project in projects" :key="project.schema">
			<div class="field">
				<input class="is-checkradio is-info" :class="{'has-background-color':isSelected(project)}" :id="project.schema" type="checkbox" :value="project" v-model="selectedProjects">
				<label :for="project.schema">{{project.name}}</label>
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		props: {
			projects: { required: true, type: Array },
			value: { required: true, type: Array }
		},
		data() {
			return {
				selectedProjects: []
			}
		},
		methods: {
			isSelected(prj) {
				const res = this.value.indexOf(prj) > -1;
				return res;
			},
		},
		watch: {
			selectedProjects(newVal) {
				this.$emit('input', newVal);
			}
		},
		created() {
			const unwatch = this.$watch('value', function (newVal) {
				this.selectedProjects = newVal.slice();
				unwatch();
			});
		},

	}
</script>
<style lang="scss" scoped>
	@import '../../assets/css/main.scss';

	.field {
		margin-bottom: 12px;
	}

	.projects {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin: 0 50px;
		max-height: calc(100vh - #{$navbar-height} - 22rem);

		@include mobile {
			max-height: 100%;
		}
	}

		.projects li {
			margin-bottom: 0.8rem;
			margin-right: 2rem;
		}
</style>
